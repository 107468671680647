import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Table, Modal, Form } from 'antd';
import moment from 'moment';

import { SearchOutlined } from '@ant-design/icons';

import './index.less';

const UserList = (props) => {
  const { users, queryParams, isLoading, fetchUsers, addUser, updateUser, setQueryParams, clearQueryParams } = props.userStore;

  const [editUser, setEditUser] = useState(undefined);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [editUser]);

  const onSubmit = () => {
    form.validateFields()
      .then((data) => {
        if (!!editUser?._id) {
          updateUser({ ...data, _id: editUser._id }, () => setEditUser(undefined));
        } else {
          addUser(data, () => setEditUser(undefined));
        }
      })
      .catch((err) => {
        console.info(err);
      });
  }

  const columns = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Company Name',
      key: 'companyName',
      dataIndex: 'companyName',
      align: 'center',
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: 'Create Time',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => {
        return <a onClick={() => setEditUser(record)}>Edit</a>;
      }
    }
  ];

  return (
    <div className='user-list'>
      <div className='user-list__topbar'>
        <div className='user-list__topbar--left'>User Management</div>

        <div className='user-list__topbar--right'>
          <div className='user-list__topbar--btn add' onClick={() => setEditUser({})}>Add</div>
        </div>
      </div>

      <div className='user-list__search-bar'>
        <div className='user-list__search-bar--cols'>
          {/*
            <Input className='user-list__search-bar--column' placeholder='Name' suffix={<SearchOutlined style={{ color: '#CACACA' }} />} value={queryParams.name} allowClear />
          */}

          <Input className='user-list__search-bar--column' placeholder='Email' suffix={<SearchOutlined style={{ color: '#CACACA' }} />} value={queryParams.email} onChange={(e) => setQueryParams('email')(e.target.value)} allowClear />

          {/*
            <Input className='user-list__search-bar--column' placeholder='Company' suffix={<SearchOutlined style={{ color: '#CACACA' }} />} value={queryParams.companyName} allowClear />
          */}
        </div>

        <div className='user-list__search-bar--btn' onClick={ () => fetchUsers() }>Search</div>
        <div className='user-list__search-bar--btn secondary' onClick={ clearQueryParams }>Clear</div>
      </div>

      <div className='user-list__content'>
        <Table
          rowKey='_id'
          loading={isLoading}
          columns={columns}
          dataSource={users.list}
          pagination={{
            pageSize: queryParams.pageSize,
            total: users.count,
            current: queryParams.page,
            onChange: (page, pageSize) => setQueryParams('page', true)(page),
            showSizeChanger: false
          }}
        />
      </div>

      <Modal
        title={editUser?._id ? 'Edit User' : 'Add User'}
        okText='Confirm'
        open={editUser}
        onCancel={() => setEditUser(undefined)}
        onOk={onSubmit}
      >
        <Form
          form={form}
          layout='vertical'
          initialValues={editUser}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Email is required.' },
              { pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/, message: 'Email does not match the format.' }
            ]}
          >
            <Input disabled={!!editUser?._id} />
          </Form.Item>

          {
            editUser?._id ? (
              null
            ) : (
              <Form.Item
                label='Password'
                name='password'
                rules={[
                  { required: true, message: 'Password is required.' },
                ]}
              >
                <Input />
              </Form.Item>
            )
          }

          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Name is required.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Company Name'
            name='companyName'
            rules={[{ required: true, message: 'Company name is required.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Company Address'
            name='address'
            rules={[{ required: true, message: 'Company address is required.' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default inject('userStore')(observer(UserList));

import { computed, makeAutoObservable, observable } from "mobx";
import Cookies from 'js-cookie';
import { message } from 'antd';

import rpgRequest from '../lib/rpgRequest.js';

const DEFAULT_QUERY = {
  page: 1,
  pageSize: 10,
};

class UserStore {
  @observable isLoading = true;
  @observable users = { list: [], count: 0 };
  @observable queryParams = { ...DEFAULT_QUERY };

  constructor() {
    makeAutoObservable(this);
  }

  clearQueryParams = () => {
    this.queryParams = { ...DEFAULT_QUERY };
    this.fetchUsers();
  }

  setQueryParams = (key, reload = false) => (value) => {
    this.queryParams = {
      ...this.queryParams,
      [key]: value
    };

    if (!!reload) {
      this.fetchUsers();
    }
  }

  addUser = (data, callback) => {
    rpgRequest('/admin/user/add', 'post', data)
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success!');
        this.fetchUsers(callback);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  updateUser = (data, callback) => {
    rpgRequest('/admin/user/update', 'post', data)
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success!');
        this.fetchUsers(callback);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  fetchUsers = (callback = () => {}) => {
    this.isLoading = true;

    rpgRequest('/admin/user/list', 'post', this.queryParams)
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          Cookies.remove('rpg_auth_token');
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        this.users = resp.data.data;
        callback();
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  findUser = (email, callback = () => {}) => {
    rpgRequest('/admin/user/list', 'post', { email })
      .then((resp) => {
        if (resp.data.code != 200) {
          Cookies.remove('rpg_auth_token');
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        callback(resp.data.data.list);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }
}

export default UserStore;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import moment from 'moment';

import { Modal, Form, Input, Select, DatePicker } from 'antd';

const MasterBLForm = (props) => {
  const { open, onSubmit, form, findUser, onCancel, userRole } = props;
  const [validUsers, setValidUsers] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [open]);

  const queryUser = useCallback(
    debounce((email) => findUser(email, setValidUsers), 500)
  , []);

  return (
    <Modal
      title='Add Master B/L'
      okText='Confirm'
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Form.Item
          label='Master B/L'
          name='masterBill'
          rules={[{ required: true, message: 'Master bill is required.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Container Number'
          name='containerNumber'
          rules={[{ required: true, message: 'Container number is required.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='House B/L'
          name='houseBill'
        >
          <Input.TextArea rows={6} placeholder='One house bill per line.' />
        </Form.Item>

        {
          userRole == 'COMPANY_ADMIN' ? (
            <Form.Item
              label='Customer Email'
              name='user'
              rules={[{ required: true, message: 'Customer email is required.' }]}
            >
              <Select
                showSearch
                onSearch={queryUser}
                options={validUsers.map((u) => ({
                  value: u._id,
                  label: u.email,
                }))}
              />
            </Form.Item>
          ) : null
        }
      </Form>
    </Modal>
  );
}

MasterBLForm.propTypes = {
  userRole: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  findUser: PropTypes.func,
  form: PropTypes.object.isRequired,
};

export default MasterBLForm;

import { computed, makeAutoObservable, observable } from "mobx";
import Cookies from 'js-cookie';
import { message } from 'antd';

import maxValue from 'lodash.max';

import rpgRequest from '../lib/rpgRequest.js';

const DEFAULT_QUERY = {
  page: 1,
  pageSize: 10,
};

class MasterBLStore {
  @observable isLoading = false;
  @observable masterbls = { list: [], count: 0 };
  @observable currentMasterBL = null;
  @observable queryParams = { ...DEFAULT_QUERY };

  constructor(authStore) {
    makeAutoObservable(this);

    this.authStore = authStore;
  }

  emptyQueryParams = () => {
    this.queryParams = { ...DEFAULT_QUERY };
    this.fetchMasterBLs();
  }

  setQueryParams = (key, reload = false) => (value) => {
    this.queryParams = {
      ...this.queryParams,
      [key]: value
    };

    if (!!reload) {
      this.fetchMasterBLs();
    }
  }

  addMasterBL = (data, callback) => {
    const { currentUser } = this.authStore;
    let API_URL = '/masterBill/add';
    if (currentUser.type == 'COMPANY_ADMIN') {
      API_URL = '/masterBill/admin/add';
    }

    rpgRequest(API_URL, 'post', data)
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success!');
        callback(resp.data.data);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  updateMasterBL = (data, callback) => {
    const { currentUser } = this.authStore;
    let API_URL = '/masterBill/update';
    if (currentUser.type == 'COMPANY_ADMIN') {
      API_URL = '/masterBill/admin/update';
    }

    rpgRequest(API_URL, 'post', data)
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success!');
        this.fetchMasterBLs(callback);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  fetchHouseBLDetail = (houseBill, callback = () => {}) => {
    rpgRequest('/houseBill/guest/find', 'post', { houseBill })
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        callback(resp.data.data);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  addHouseBL = (data, masterBill, callback) => {
    const { currentUser } = this.authStore;
    let API_URL = '/houseBill/add';
    if (currentUser.type == 'COMPANY_ADMIN') {
      API_URL = '/houseBill/admin/add';
    }

    rpgRequest(API_URL, 'post', data)
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success!');
        this.fetchMasterBLDetail(masterBill, callback);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  updateHouseBL = (data, masterBill, feeId, callback) => {
    const { currentUser } = this.authStore;
    let API_URL = '/houseBill/update';
    if (currentUser.type == 'COMPANY_ADMIN') {
      API_URL = '/houseBill/admin/update';
    }

    rpgRequest(API_URL, 'post', data)
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        if (currentUser.type == 'COMPANY_ADMIN') {
          if (data.hazmat == 'Yes') {
            const hazMat = maxValue([5000, 600 * (data.volume || 0), +((data.weight / 800) * 600).toFixed(0)]);
            this.updateHouseBLFee({
              _id: feeId,
              hazMat,
            });
          } else if (data.hazmat == 'No') {
            this.updateHouseBLFee({
              _id: feeId,
              hazMat: -1,
            });
          }
        }

        message.success('Success!');
        this.fetchMasterBLDetail(masterBill, callback);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  removeHouseBL = (_id, masterBill) => {
    rpgRequest('/houseBill/remove', 'post', { _id })
      .then((resp) => {
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success!');
        this.fetchMasterBLDetail(masterBill);
      })
      .catch((err) => {
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  updateHouseBLFee = (data, callback = () => {}) => {
    this.isLoading = true;

    rpgRequest('/houseBill/admin/fee/update', 'post', data)
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        this.fetchMasterBLDetail(this.currentMasterBL._id);
        message.success('Success!');
        callback(resp.data.data);
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  fetchMasterBLs = (callback = () => {}) => {
    this.isLoading = true;

    rpgRequest('/masterBill/list', 'post', this.queryParams)
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          Cookies.remove('rpg_auth_token');
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        this.masterbls = resp.data.data;
        callback();
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  fetchMasterBLDetail = (_id, callback = () => {}) => {
    this.isLoading = true;

    rpgRequest('/masterBill/detail', 'post', { _id })
      .then((resp) => {
        this.isLoading = false;
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        this.currentMasterBL = resp.data.data;
        callback(resp.data.data);
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  clearCurrentMasterBL = () => {
    this.currentMasterBL = null;
  }

  clientQueryMasterBL = (data) => {
    this.isLoading = true;

    return rpgRequest('/masterBill/guest/find', 'post', data)
      .then((resp) => {
        this.isLoading = false;
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        return resp.data.data;
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  clientQueryHouseBL = (data) => {
    this.isLoading = true;

    return rpgRequest('/houseBill/guest/find', 'post', data)
      .then((resp) => {
        this.isLoading = false;
        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        return resp.data.data;
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }
}

export default MasterBLStore;
